<template>
  <div>
    <div class="banner_info" style="background: url(images/7_02.jpg) center no-repeat;background-size: cover;">
      <!--        <img src="images/7_03.png">-->
    </div>
    <div class="infopage">
      <div class="container clearfix">
        <div class="left">
          <div class="box">
            <dl>
              <dt>通知公告</dt>
              <dd>Notice announcement</dd>
            </dl>
            <ul>
              <li v-for="item in tzgg.articleList" :key="item.id" @click="toDetail(item.id,'通知公告')"><a href="javascript:;">{{item.title}}</a></li>
            </ul>
          </div>
          <div class="box">
            <dl>
              <dt>法律法规</dt>
              <dd>laws and regulations</dd>
            </dl>
            <ul>
              <li v-for="item in flfg.articleList" :key="item.id" @click="toDetail(item.id,'法律法规')"><a href="javascript:;">{{item.title}}</a></li>
            </ul>
          </div>
        </div>
        <div class="right">
          <div class="title flex lg">
            <div class="flex_item"><b class="">{{name}}</b></div>
            <div class="bread">
<!--              <a href="#">首页</a> > <a href="#">大师傅</a> > <span>方法</span>-->
            </div>
          </div>
          <div class="newsitem" >
            <div class="newsitem-title">
              {{articleDetail.title}}
            </div>
            <div class="source">
              <div class="t1">
                来源:
              </div>
              <div class="t2">
                {{articleDetail.source}}
              </div>
              <div class="t3">
                时间:
              </div>
              <div class="t4">
                {{articleDetail.createTime}}
              </div>
            </div>
            <div v-html="articleDetail.content || ''">

            </div>
            <div v-if="articleDetail.fileUrlList.length != 0">
              <span>附件：</span>
                <ul>
                  <li v-for="(item,index) in articleDetail.fileUrlList" :key="index"><a :href="item.url">{{item.name}}</a></li>
                </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      articleDetail:'',
      tzgg:[],
      flfg:[],
      articleId:0,
      name:'',
      tabArray:['通知公告','法律法规']
    }
  },
  async mounted() {
    this.articleId = this.$route.query.articleId;
    this.name = this.$route.query.name
    document.title = "中汽认证中心-" + this.name
    //获取左侧固定数据
    let res1 = await this.$api.getColumn({columnName:'通知公告'});
    this.tzgg = res1.data.data[0]
    let res2 = await this.$api.getColumn({columnName:'法律法规'});
    this.flfg = res2.data.data[0];
    //获取详情
    this.getArticleData(this.articleId)
  },
  methods:{
    async getArticleData(articleId){
      let res = await this.$api.getArticleData({id:articleId});
      this.articleDetail = res.data.data;
      if(this.name == null){
        this.name = res.data.data.columnName;
        document.title = "中汽认证中心-" + this.name
      }
      console.log(res.data)
    },
    toDetail(id,name){
      this.$router.push({path:'/view',query:{articleId:id,name:name}})
    },
    reloadData(id,name){
      this.name = name;
      this.articleId = id
      this.getArticleData(this.articleId)
    }
  }
}
</script>

<style scoped>

</style>
